/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/secure.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Secure = () => {

  return (
    <section className={styles.secure}>
      <span className={styles.modalHeader}><Title title="New" subtitle="Password" /></span>
      <p className={styles.instructionsModal}>Create your password and select 'Submit'.</p>
      <div className={styles.center}>
        <form className={styles.form}>
          <div className={styles.row2}>
            <span className={styles.password}><input
              type="password"
              name="password"
              id="password"
              placeholder="Choose a password."
              className={styles.formControl}
            /></span>
            <span className={styles.password}><input
              type="password"
              name="password"
              id="password"
              placeholder="Re-enter your password."
              className={styles.formControl}
            /></span>
          </div>
          <div className={styles.arrowButton}>
            <input
              type="submit"
              value="submit"
              className={styles.submit}
            />
          </div>
        </form>
      </div>
    </section>
  )
}

export default Secure
